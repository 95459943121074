<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- ADD USERS FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add User">
          <form>
            <div class="row">
              <div class="col-6 mb-2">
                <h6>Select User Type*</h6>

                <multiselect
                  v-model="form.user_type"
                  track-by="type"
                  label="name"
                  :options="userTypeList"
                  name="User Type"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  deselect-label="Can't remove this value"
                >
                  <!-- <template slot="singleLabel" slot-scope="{ option }">{{
                    option.company_name
                  }}</template>-->
                </multiselect>
                <p class="error-msg">
                  <span class="User Type" v-show="errors.has('User Type')">
                    {{
                    errors.first("User Type")
                    }}
                  </span>
                </p>
              </div>

              <div class="col-6 mb-2" v-if="form.user_type.type == '2'">
                <h6>Select User*</h6>

                <multiselect
                  v-model="form.existing_user"
                  track-by="user_email"
                  label="user_email"
                  :options="UserList"
                  name="User Type"
                  :searchable="true"
                  open-direction="bottom"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                >
                  <!-- <template slot="singleLabel" slot-scope="{ option }">{{
                    option.company_name
                  }}</template>-->
                </multiselect>
                <p class="error-msg">
                  <span class="User Type" v-show="errors.has('User Type')">
                    {{
                    errors.first("User Type")
                    }}
                  </span>
                </p>
              </div>
            </div>

            <div v-if="form.user_type.type == '1'">
              <div class="row">
                <div class="col-6 mb-2">
                  <h6>Name*</h6>

                  <vs-input
                    v-validate="'required|alpha_spaces'"
                    name="Name"
                    v-model="form.user_name"
                    class="w-full"
                  />
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Name')"
                    >{{ errors.first("Name") }}</span>
                  </p>
                </div>

                <div class="col-6 mb-2">
                  <h6>Mobile No.*</h6>
                  <vs-input
                    v-validate="'required|numeric|max:14'"
                    name="Mobile Number"
                    v-model="form.user_mobile"
                    class="w-full"
                  />
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Mobile Number')"
                    >{{ errors.first("Mobile Number") }}</span>
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-6 mb-2">
                  <h6>Email*</h6>

                  <vs-input
                    v-validate="'required|email'"
                    name="Email"
                    v-model="form.user_email"
                    class="w-full"
                  />
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Email')"
                    >{{ errors.first("Email") }}</span>
                  </p>
                </div>

                <div class="col-6 mb-2">
                  <h6>User Password*</h6>
                  <vs-input
                    v-validate="'required|min:6'"
                    class="w-full"
                    type="password"
                    name="Password"
                    v-model="form.user_password"
                  />
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Password')"
                    >{{ errors.first("Password") }}</span>
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-6 mb-5">
                  <h6>Address*</h6>

                  <vs-textarea
                    v-validate="'required'"
                    name="Address"
                    v-model="form.user_address"
                    class="w-full mb-0"
                    rows="4"
                  />
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Address')"
                    >{{ errors.first("Address") }}</span>
                  </p>
                </div>

                <div class="col-6 mb-5">
                  <div class="row">
                    <div class="col-6">
                      <h6>Date of Birth</h6>

                      <flat-pickr
                        class="rounded bg-none height-inherit"
                        v-model="user_dob"
                        :config="config"
                      />
                    </div>

                    <div class="col-6">
                      <h6>Gender*</h6>
                      <ul name="Gender" class="centerx list-inline">
                        <li class="list-inline-item">
                          <vs-radio v-model="form.user_gender" vs-value="Male">Male</vs-radio>
                        </li>
                        <li class="list-inline-item">
                          <vs-radio v-model="form.user_gender" vs-value="Female">Female</vs-radio>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6 mb-2">
                  <h6>User's Agreement</h6>
                  <vue-dropzone
                    @vdropzone-error="vdropzoneError"
                    @vdropzone-complete="vdropzoneComplete"
                    @vdropzone-files-added="vdropzoneFilesAdded"
                    @vdropzone-success="vdropzoneSuccessFile"
                    ref="myVueDropzoneFile"
                    id="dropzone"
                    class="rounded"
                    :options="dropzoneOptionsFiles"
                  ></vue-dropzone>
                  <vs-button
                    v-if="fileUpload.deleteButtonStatus"
                    type="filled"
                    @click.prevent="clearFile()"
                    class="mr-3 feather icon-trash"
                  />
                </div>

                <div class="col-6 mb-4">
                  <h6>Upload User Image</h6>
                  <vue-dropzone
                    @vdropzone-error="vdropzoneError"
                    @vdropzone-complete="vdropzoneComplete"
                    @vdropzone-files-added="vdropzoneFilesAdded"
                    @vdropzone-success="vdropzoneSuccess"
                    ref="myVueDropzone"
                    id="dropzone"
                    class="rounded"
                    :options="dropzoneOptions"
                  ></vue-dropzone>
                  <vs-button
                    v-if="imageUpload.deleteButtonStatus"
                    type="filled"
                    @click.prevent="clearImage()"
                    class="mr-3 feather icon-trash"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6 mb-2">
                  <h6>Company*</h6>

                  <multiselect
                    v-model="company_value"
                    track-by="company_id"
                    label="company_name"
                    @input="
                      ({ company_id }) => (this.form.company_id = company_id)
                    "
                    :options="companies"
                    name="Company Name"
                    :searchable="false"
                    :allow-empty="false"
                    open-direction="bottom"
                    @select="onSelectCompany"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{
                      option.company_name
                      }}
                    </template>
                  </multiselect>
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Company Name')"
                    >{{ errors.first("Company Name") }}</span>
                  </p>
                </div>

                <div class="col-6 mb-2">
                  <h6>Department*</h6>
                  <multiselect
                    v-model="department_value"
                    track-by="department_id"
                    label="department_name"
                    @input="
                      ({ department_id }) =>
                        (this.form.department_id = department_id)
                    "
                    :options="byCompanyDepartmentData"
                    name="Department Name"
                    :searchable="false"
                    :allow-empty="false"
                    open-direction="bottom"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{
                      option.department_name
                      }}
                    </template>
                  </multiselect>
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Department Name')"
                    >{{ errors.first("Department Name") }}</span>
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-6 mb-2">
                  <h6>Designation*</h6>
                  <multiselect
                    v-validate="'required'"
                    v-model="designation_value"
                    track-by="designation_id"
                    label="designation_name"
                    @input="
                      ({ designation_id }) =>
                        (this.form.designation_id = designation_id)
                    "
                    :options="byCompanyDesignationData"
                    name="Designation Name"
                    :searchable="false"
                    :allow-empty="false"
                    open-direction="bottom"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{
                      option.designation_name
                      }}
                    </template>
                  </multiselect>
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Designation Name')"
                    >{{ errors.first("Designation Name") }}</span>
                  </p>
                </div>

                <div class="col-6 mb-2">
                  <h6>Role*</h6>
                  <multiselect
                    v-model="role_value"
                    track-by="role_id"
                    label="role_name"
                    @input="({ role_id }) => (this.form.role_id = role_id)"
                    :options="roles"
                    name="User Role"
                    :searchable="false"
                    :allow-empty="false"
                    open-direction="bottom"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{
                      option.role_name
                      }}
                    </template>
                  </multiselect>
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('User Role')"
                    >{{ errors.first("User Role") }}</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6 mb-2">
                <h6>Add Level*</h6>

                <vs-input
                  type="number"
                  v-validate="'required'"
                  name="Level"
                  v-model="form.contract_level"
                  class="w-full"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Level')"
                  >{{ errors.first("Level") }}</span>
                </p>
              </div>
            </div>

            <vs-button
              :disabled="submitStatus"
              type="filled"
              @click.prevent="submitForm"
              class="mt-5 mr-3"
            >Submit</vs-button>

            <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button>
            <div class="vx-col">
              <div class="excel-btn-1" v-if="false">
                <vx-tooltip text="Template Excel" position="left">
                  <vs-button
                    type="border"
                    class="py-2"
                    @click.native="
                      onClickSampleDownload(excelurl + `/v1/users/excel/sample`)
                    "
                  >
                    <img
                      class="d-inline-flex"
                      src="@/assets/images/icons/excel.svg"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </vx-tooltip>
              </div>

              <div class="excel-btn-2" v-if="false">
                <vx-tooltip text="Import Excel" position="left">
                  <label for="import-file">
                    <input
                      ref="uploadExcelFile"
                      @change="uploadExcel($event)"
                      type="file"
                      name="import-file"
                      id="import-file"
                      class="d-none"
                    />
                    <!-- class="d-none" -->
                    <div class="vs-component vs-button px-4 vs-button-primary vs-button-filled">
                      <span class="d-inline-flex pr-5 text-white">Import</span>
                      <img
                        class="d-inline-flex"
                        src="@/assets/images/icons/import-excel.svg"
                        height="20"
                        width="20"
                      />
                    </div>
                  </label>
                </vx-tooltip>
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import UserService from "@/services/UserService.js";
import RolesService from "@/services/userRolesService.js";
import companyService from "@/services/companyService.js";
import employeeService from "@/services/employeeService.js";
import departmentService from "@/services/departmentService.js";
import designationService from "@/services/designationService.js";
import uploadService from "@/services/uploadService.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import axios from "axios";

import constant from "@/helper/constant.js";
const VxTour = () => import("@/components/VxTour.vue");
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
    flatPickr,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      excelurl: BASEURL,
      //  maxDate: new Date().fp_incr(-6600),
      config: {
        maxDate: new Date(),
        dateFormat: "d-m-Y",
      },
      file: [],
      byCompanyDepartmentData: [],
      byCompanyDesignationData: [],
      companies: [],
      departments: [],
      designations: [],
      designation_value: [],
      department_value: [],
      company_value: [],
      role_value: [],
      dropzoneOptions: {
        url: BASEURL + "/v2/uploads",
        ...constant.DEFAULT_IMAGE,
        acceptedFiles: "image/*",
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsFiles: {
        url: BASEURL + "/v2/uploads",
        ...constant.DEFAULT_FILES,
        acceptedFiles: ".pdf,.xls,.xlsx,.csv",
        headers: { "My-Awesome-Header": "header value" },
      },
      imageUpload: {
        image_url: "",
        deleteButtonStatus: false,
      },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      submitStatus: true,
      //userdata
      user_dob: "",
      user_pic: "",
      user_agreement: "-",
      userTypeList: [
        {
          name: "Existing User",
          type: "2",
        },
        {
          name: "New User",
          type: "1",
        },
      ],
      form: {
        user_type: {
          name: "New User",
          type: "1",
        },
        existing_user: "",
        contract_level: "",
        user_name: "",
        user_email: "",
        user_password: "",
        user_address: "",
        user_mobile: "",
        company_id: "",
        user_gender: "Male",
        department_id: "",
        designation_id: "",
        role_id: "",
        city_id: 1,
      },
      User: "Select User",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      roles: [],
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        {
          text: "New York",
          value: "new-york",
        },
        {
          text: "Chicago",
          value: "chicago",
        },
        {
          text: "San Francisco",
          value: "san-francisco",
        },
        {
          text: "Boston",
          value: "boston",
        },
      ],
      columnDefs: [
        {
          headerName: "User Name",
          field: "firstname",
          width: 275,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: "Expense Head",
          field: "lastname",
          filter: true,
          width: 275,
        },
        {
          headerName: "Expense Category",
          field: "email",
          filter: true,
          width: 375,
          //   pinned: "left"
        },
        {
          headerName: "Amount (₹)",
          field: "company",
          filter: true,
          width: 200,
        },
        {
          headerName: "Expense Date",
          field: "city",
          filter: true,
          width: 200,
        },
        {
          headerName: "Status",
          field: "state",
          filter: true,
          width: 150,
        },
      ],
      contacts: contacts,
      // FORM DETAILS
      required: "",
      alpha: "",
      alpha_dash: "",
      alpha_num: "",
      numeric: "",
      alpha_spaces: "",
      email: "",
      url: "",
      min: "",
      max: "",
      password: "",
      confirm_password: "",
      date_format: "",
      between: "",
      credit_card: "",
      date_between: "",
      digits: "",
      select1: "",
      UserList: [],
      options1: [
        {
          text: "ABC Name",
          value: 1,
        },
        {
          text: "ABC Name",
          value: 2,
        },
        {
          text: "ABC Name",
          value: 3,
        },
        {
          text: "ABC Name",
          value: 4,
        },
        {
          text: "ABC Name",
          value: 5,
        },
      ],
      max_value: "",
      min_value: "",
      regex: "",
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      // if (val <= 576) {
      //   this.maxPageNumbers = 4;
      //   this.gridOptions.columnApi.setColumnPinned("email", null);
      // } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length - 1;
        console.log(
          len,
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        );

        if (
          this.form.user_type.type == "2" &&
          this.form.existing_user != "" &&
          this.form.contract_level != ""
        ) {
          this.submitStatus = false;
        } else if (
          len <=
            Object.keys(newVal).filter(
              (x, index) =>
                newVal[x] &&
                newVal[x] !== undefined &&
                String(newVal[x]).trim().length > 0
            ).length &&
          this.form.user_type.type == "1"
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onSelectCompany: function ({ company_id }) {
      this.designation_value = [];
      this.department_value = [];
      this.role_value = [];
      (this.byCompanyDepartmentData = []), this.$validator.reset();
      // this.byCompanyDepartmentData = [];
      // this.byCompanyDesignationData = [];
      if (company_id) {
        UserService.getDesignationByCompany(company_id).then((response) => {
          this.byCompanyDesignationData = [];
          const { data } = response;
          if (data.Status == true) {
            data.data.map((designation, index) => {
              designation.id = designation.designation_id;
              designation.label = designation.designation_name;
              designation.name = designation.designation_name;
              this.byCompanyDesignationData.push(designation);
            });
            // this.byCompanyDesignationData = this.byCompanyDepartmentData;
          }
        });
        UserService.getDepartmentByCompany(company_id)
          .then((response) => {
            const { data } = response;
            if (data.Status == true) {
              data.data.map((department, index) => {
                department.id = department.department_id;
                department.label = department.department_name;
                department.name = department.department_name;
                this.byCompanyDepartmentData.push(department);
              });
              // this.byCompanyDesignationData = this.byCompanyDepartmentData;
            }
          })
          .catch((error) => {
            console.log("error :", error);
          });
      }
    },

    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },
    uploadExcel: function (event) {
      let that = this;
      that.$vs.loading();
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      axios
        .post(that.excelurl + `/v1/users/import/excel`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const { data } = response;
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          if (
            data &&
            data["error"] &&
            data.error["message"] &&
            data.error.message.length > 0
          ) {
            that.$vs.notify({
              // title: "Duplicate entries have not been imported.",
              title: "Error!",
              text: data.error.message || "Error",
              // time:8000,
              time: 35000,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          } else {
            that.$vs.notify({
              title: "Updated!",
              text: data.data.message || "Updated",
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            eventBus.$emit("refreshuserTable", data);
          }
          that.$vs.loading.close();
          // that.$vs.notify({
          //   title: "Updated!",
          //   text: data.message,
          //   iconPack: "feather",
          //   icon: "check_box",
          //   color: "success"
          // });
          eventBus.$emit("refreshuserTable", data);
        })
        .catch((error) => {
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";

          that.$vs.loading.close();
          that.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    clearImage: function () {
      this.imageUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzone.removeAllFiles();
      this.$refs.myVueDropzone.enable();
    },
    clearFile: function () {
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.$refs.myVueDropzoneFile.removeAllFiles();
    },
    vdropzoneFilesAdded: function (file) {
      console.log("file 1 :", file);
    },

    vdropzoneSuccessFile: function (file, response) {
      this.user_agreement = response.data.image_path;
      this.fileUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzoneFile.disable();
    },

    vdropzoneSuccess: function (file, response) {
      this.user_pic = response.data.image_path;
      this.imageUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzone.disable();
    },
    vdropzoneComplete: function (response) {
      console.log("response :", response);
    },
    vdropzoneError: function (file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    getCompanyList: function () {
      companyService
        .getAllCompanies()
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.companies = data.data;
            if (this.companies.length > 0) {
              // this.form.company_id = this.companies[0].company_id;
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    // DEPARTMENT LIST
    getDepartmentList: function () {
      departmentService
        .getAllDepartments()
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.departments = data.data;
            if (this.departments.length > 0) {
              // this.form.department_id = this.departments[0].department_id;
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    //  DESIGNATION LIST
    getDesignationList: function () {
      designationService
        .getAllDesignations()
        .then((response) => {
          const { data } = response;
          if (data.err) {
          } else {
            this.designations = data.data;
            if (this.designations.length > 0) {
              // this.form.designation_id = this.designations[0].designation_id;
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getRolesList: function () {
      console.log("RolesService :", RolesService);
      RolesService.getAllUserRoles()
        .then((response) => {
          const { data } = response;
          if (data.err) {
          } else {
            this.roles = data.data;
            if (this.roles.length > 0) {
              // this.form.role_id = this.roles[0].role_id;
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    editForm() {
      delete this.form.existing_user.user_password;

      //  console.log("object", this.form);
      // this.$validator.validateAll().then(result => {
      this.$vs.loading();
      // console.log("result", result);
      // if (result) {
      // let id = this.params.data.user_id;
      let payload = this.form.existing_user;

      payload["contract_active"] = 1;
      payload["contract_level"] = this.form.contract_level;
      payload["user_dob"] = this.form.user_dob
        ? this.form.user_dob
        : "1970-01-01";
      // console.log(payload);
      // return false;

      UserService.EditUser(payload, this.form.existing_user.user_id)
        .then((response) => {
          this.$vs.loading.close();
          this.submitStatus = false;
          const { data } = response;
          this.popupActive = false;
          if (!data.error) {
            this.$vs.notify({
              title: "Inserted!",
              text: "Data Added Successfully",
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            setTimeout(() => {
              eventBus.$emit("refreshuserTable", data);
            }, 1);
            this.clearForm();
          } else {
            this.$vs.loading.close();
            // console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.error.message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.submitStatus = true;
          console.log("error:", error);
        });
      //   } else {
      //     //form have errors
      //   }
      // });
    },
    submitForm() {
      //  console.log("object");
      if (this.form.user_type.type == "2") {
        console.log("fdsfds");
        this.editForm();
        return false;
      }

      // this.$validator.validateAll().then(result => {
      // console.log("result", result);
      // if (result) {
      this.$vs.loading();
      this.submitStatus = false;
      let payload = {
        company_id: this.form.company_id,
        department_id: this.form.department_id,
        designation_id: this.form.designation_id,
        user_mobile: this.form.user_mobile,
        user_email: this.form.user_email,
        role_id: this.form.role_id,
        user_address: this.form.user_address,
        city_id: 1,
        user_password: this.form.user_password,
        user_name: this.form.user_name,
        user_gender: this.form.user_gender,
        user_active: 1,
        user_dob: this.user_dob,
        user_pic: this.user_pic,
        user_agreement: this.user_agreement,
        contract_active: this.form.user_type.type,
        contract_level: this.form.contract_level,
      };
      // console.log("payload :", payload);
      UserService.AddUser(payload)
        .then((response) => {
          this.$vs.loading.close();
          this.submitStatus = false;
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.clearForm();
            this.clearImage();
            this.clearFile();
            setTimeout(() => {
              eventBus.$emit("refreshuserTable", data);
            }, 1);
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.submitStatus = true;
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
      // if form have no errors
      // alert("form submitted!");
      // } else {
      //   // form have errors
      // }
      // //  });
    },
    clearForm: function () {
      this.user_dob = "";
      this.user_pic = "";
      this.user_agreement = "-";
      Object.keys(this.form).map((x, index) => {
        if (x === "city_id") {
          this.form[x] = "1";
        } else if (x === "user_active") {
          this.form[x] = "1";
        } else if (x === "user_gender") {
          this.form[x] = "Male";
        } else if (x === "user_type") {
          this.form[x] = {
            name: "New User",
            type: "1",
          };
        } else {
          this.form[x] = "";
        }
      });

      this.designation_value = [];
      this.department_value = [];
      this.company_value = [];
      this.role_value = [];
      this.$validator.reset();
      this.clearImage();
      this.clearFile();
    },
  },
  mounted() {
    eventBus.$on("UserList", (e) => {
      console.log(e);
      this.UserList = e.filter((x) => {
        return x.employment_active == 1;
        // return x;
      });
    });
    this.getCompanyList();
    this.getDepartmentList();
    this.getDesignationList();
    this.getRolesList();
    this.gridApi = this.gridOptions.api;
  },
};
</script>
