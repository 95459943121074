<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name:
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- DEPARTMENTS LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>Users</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <!-- <vx-tooltip text="Export Excel">
                <a
                  :href="
                    excelurl +
                      `/v1/users/excel/download?${temp_token}&&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive != 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>-->

              <vx-tooltip text="Export Archive Excel">
                <a
                  :href="
                    excelurl +
                      `/v1/users/excel/download?${temp_token}&archive=1&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive == 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="users"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import UserService from "@/services/UserService.js";
import userStatus from "./userStatus";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
const VxTour = () => import("@/components/VxTour.vue");
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import userArchive from "./userArchive.vue";
import SearchService from "@/services/searchService.js";
import editUser from "./editUser.vue";
import userDetails from "./userDetails.vue";
import { BASEURL } from "@/config/index.js";
export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd
  },
  props: ["listType"],
  data() {
    return {
      tempToken:window.localStorage.getItem("TempToken"),
      excelurl: BASEURL,
      totalPages: 0,
      archive: this.listType,
      User: "Select User",
      users: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null
      },
      configTodateTimePicker: {
        minDate: null
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" }
      ],
      columnDefs: [],
      contacts: contacts
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        // if (this.gridApi) return this.gridApi.paginationGoToPage(val - 1);
        if (val) {
          this.getuserlist(val);
          return val;
        } else return 1;
      }
    }
  },
  methods: {
    updateSearchQuery(val) {
      let filter = {
        contracy_active: 1
      };
      console.log(filter);
      if (this.archive == "archive") {
        filter["archive"] = true;
      }

      UserService.getAllSerchResultV2(val, "users", filter)
        .then(response => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.users = data.data.filter(x => {
              // console.log(contract_active);
              return x.contract_active > 0;
            });
          } else {
            this.users=[]
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning"
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },

    getUserList: function(currentPage) {
      let filter = {
        page: currentPage,
        contracy_active: 1
      };
      console.log(filter);
      if (this.archive == "archive") {
        filter["archive"] = true;
      }
      UserService.UserList(filter)
        .then(response => {
          const { data } = response;

          if (data.err || data.Status == false) {
          } else {
            this.users = data.data.filter(x => {
              // console.log(contract_active);
              return x.contract_active > 0;
            });
            eventBus.$emit("UserList", data.data);
            this.totalPages = data.pagination.last_page;
            this.gridApi.sizeColumnsToFit();
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  beforeMount() {
    eventBus.$on("refreshuserTable", e => {
      this.getUserList(this.currentPage);
    });
  },

  mounted() {
    this.columnDefs = [
      {
        headerName: "Image",
        field: "user_pic",
        width: 100,
        sortable: false,
        suppressSizeToFit: true,
        cellRenderer: function(params) {
          if (params.value && params.value.length > 0) {
            return `<img  class="img-rounded" src=${params.value+'?token='+tempToken} />`;
          } else {
            return `<img  class="img-rounded" src="https://api.gokozo.com/uploads/8e834a837c03cb52_org.png"/>`;
          }
        }
      },
      {
        headerName: "Name",
        field: "user_name",
        width: 200
        // checkboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // headerCheckboxSelection: true,
      },
      {
        headerName: "Email",
        field: "user_email",
        width: 275
      },
      // {
      //   headerName: "Gender",
      //   field: "user_gender",
      //   width: 120,
      // },
      // {
      //   headerName: "Mobile Number",
      //   field: "user_mobile",
      //   width: 165,
      // },
      // {
      //   headerName: "Designation",
      //   field: "",
      //   width: 170,
      // },
      // {
      //   headerName: "City",
      //   field: "city_name",
      //   width: 140,
      // },
      // {
      //   headerName: "Address",
      //   field: "user_address",
      //   width: 550,
      // },
      // {
      //   headerName: "DOB",
      //   field: "user_dob",
      //   width: 150,
      // },
      // {
      //   headerName: "country",
      //   field: "country",
      //   width: 150,
      // },

      // {
      //   headerName: "Password of App",
      //   field: "country",
      //   width: 150
      // },
      // {
      //   headerName: "Employee Type",
      //   field: "role_name",
      //   width: 150,
      // },
      // {
      //   headerName: "Details",
      //   field: "user_id",
      //   width: 100,
      //   sortable: false,
      //   suppressToolPanel: true,
      //   cellStyle: { "text-align": "center" },
      //   cellRendererFramework: userDetails
      // },
      {
        headerName: "Status",
        field: "",
        width: 100,
        sortable: false,
        hide: this.archive == "archive" ? true : false,

        cellStyle: { "text-align": "center" },
        cellRendererFramework: userStatus
      },

      {
        headerName: "Edit",
        field: "",
        width: 95,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: editUser
      },
      {
        headerName: this.archive == "archive" ? "Unarchive" : "Archive",
        field: this.archive ? "1" : "4",
        width: this.archive ? 140 : 104,
        sortable: false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: userArchive
      }
    ];

    if (this.checkPermission(["Users", "GET"])) {
      this.getUserList(this.currentPage);
    }

    // this.agGrid.columnApi.setColumnsVisible(["Status"], false);

    this.gridApi = this.gridOptions.api;
  }
};
</script>
<style>
.img-rounded {
  border-radius: 50%;
  max-width: 40px;
  margin-top: 2px;
  height: 40px;
  object-fit: cover;
  object-position: center;
}
</style>
